import React from 'react';
import { Link } from 'react-router-dom';
import json from '../assets/json/works';

const SinglePostNav = () => {
    let index;
    let data = json.works;
    data.find(function(item, i) {
        index = (item.slug === i) ? i : -1;
        return index;
    });
    

    const getPostNav = (idx) => {
        switch(idx) {
            case -1: return;
            case 0: return getNext(idx);
            case data.length-1: return getPrev(idx);            
            default: return getBoth(idx);
        }
    }

    const getPrev = (idx) => {
        idx--;
        return (
        <div className="postNav-prev">
            <p className="postNav-title">Previous Work</p>
            <Link to={data[idx].slug}>{data[idx].name}</Link>
        </div>
        );
    }

    const getNext = (idx) => {
        idx++;
        return (
        <div className="postNav-next">
            <p className="postNav-title">Next Work</p>
            <Link to={data[idx].slug}>{data[idx].name}</Link>
        </div>
        );
    }

    const getBoth = (idx) => {
        return (
            <div className="postNav-pn">
                { getPrev(idx) }                
                { getNext(idx) }
            </div>
        )
    }

    return(
        <div className="postNav-wrapper">
            { getPostNav(index) } 
        </div>
    );
}
export default SinglePostNav;