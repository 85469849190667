import React from 'react';
import VProjectBtn from '../VProjectBtn';
import json from '../../assets/json/works';
import json2srcset from '../../utilities/json2srcset';

const ReelTalk = () => {
    let data = json.works[2];

    return (
        <section className="single-work rtlk">
            {json2srcset(data, "full-width rt-splash", "splash")}
            <h1>ReelTalk Movie Database</h1>
            <div className="single-reeltalk single-p">
                <p>The ReelTalk Movie Database is a BCIT project that implements The Movie DB API through a ReactJS application. A method to browse, sort and filter movies pulled from an API.</p>
                <p>This was completed as part of a team, and I was tasked with handling the JavaScript and React code for filtering, sorting and searching the movies, as well as understanding how the API worked. Another feature was favoriting, which involved storing information locally on the user's browser.</p>
                    {json2srcset(data, "rt-screen inner-img", "screen")}
                    <p>Searching was certainly the most difficult feature to be added, especially from the UX perspective, as many behaviours had to be handled properly with respect to how the user interacted with it and would expect it to behave. Regrettably, it was not fully completed due to time constraints as there are still a few edge-case scenarios where it does not behave as expected.</p>

                    {json2srcset(data, "rt-screen inner-img", "process")}
                    <p>The original aesthetic concept and mockup of the app was also created by me, although there were a few tweaks along the way that developed as a result of the team putting their best brains together. Pictured is the original tablet-view mockup. The team came up with new ways to handle or display interactions, such as the favoriting functionality and the single movie page were created after the initial concept with feedback and input.</p>

            </div>
            <div className="vproj-wrapper">
                <VProjectBtn url={"../projects/reeltalk"} />
            </div>
        </section>
    );
}

export default ReelTalk;