import React from 'react';
import VProjectBtn from '../VProjectBtn';
import json from '../../assets/json/works';
import json2srcset from '../../utilities/json2srcset';

const PhantomBuster = () => {
    let data = json.works[3];

    return (
        <section className="single-work pbuster">
            {json2srcset(data, "full-width pb-splash", "splash")}
            <h1>Phantom Buster</h1>
            <section className="">
                <div className="single-portfolio single-p">
                    <p>This is a small, retro arcade-styled top-down shooter game created with HTML Canvas and JavaScript over the course of two weeks. You play as a lone soldier who fights against hordes of zombie-ghosts, armed with his trusty blaster. Aim for the high score! To note, this game is unplayable on mobile devices due to lack of method of input - there was some plan about it with laying down the controls at the bottom of the screen, but it never reached the implementation stage.</p>
                    {json2srcset(data, "pb-screen inner-img", "screen")}
                    <p>From the start, I had heard of games of being created in something known as Canvas, but had never actually tried making one, so it was the perfect opportunity to challenge myself with it. Starting with a tutorial as a base, I included additional features to add some more complexity and gameplay elements to it, such as HP, healing, enemies with more than 1 HP, sounds, four-way shooting with accompanying sprites and animation, a strafing mode, and HUD elements.</p>
                    {json2srcset(data, "pb-process inner-img", "process")}
                    <p>It was a great learning experience, and there's much more to Canvas than just making games that I could learn in the future - although actually making a game was quite an interesting process in itself as well! It's always fun to be able to whip up some sprites. One thing I'd like to be able to do if I revisited this project would be to give the enemies some sort of AI, and maybe different weapon types.</p>
                </div>
            </section>
            <div className="vproj-wrapper">
                <VProjectBtn url={"../projects/phantombuster"} />
            </div>
            
        </section>
    );
}

export default PhantomBuster;