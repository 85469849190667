import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../components/Home';
import About from '../components/About';
import Works from '../components/Works';
import SingleWork from '../components/SingleWork';
import Contact from '../components/Contact';
import HomeCanvas from '../components/HomeCanvas';
import PageNotFound from '../components/PageNotFound';
import { APP_FOLDER_NAME } from '../globals/variables';

const AppRouter = () => {
    return (
        <Router basename={APP_FOLDER_NAME}>
            <div className="wrapper">
                <Header/>
                
                <Switch>
                    <Route path={'/'} exact><HomeCanvas /><Home /></Route>
                    <Route path={'/about'} exact><HomeCanvas /><About /></Route>
                    <Route path={'/works'} exact><HomeCanvas /><Works /></Route>
                    <Route path={'/works/:id'} ><SingleWork /></Route>

                    <Route path={'/projects'} exact><Redirect to={'../works/'} /></Route>
                    <Route path={'/projects/*'}><Redirect to={'../../works/'} /></Route>

                    <Route path={'/contact'} exact><HomeCanvas /><Contact /></Route>
                    
                    <Route path={'/*'}><HomeCanvas /><PageNotFound /></Route>
                </Switch>
                <Footer />
            </div>
        </Router>

    );
}

export default AppRouter;