import React from 'react';
import { Link } from 'react-router-dom';
import json from '../assets/json/works';
import json2srcset from '../utilities/json2srcset';

const WorkBlocks = () => {

    const parseWB = () => {
        return json.works.map((block, i) => {
            return workBlock(block, i);
        })
    }

    const workBlock = (data, key) => {
        return (
            <Link key={key} to={`/works/${data.slug}`} >
                <div className="workblock">
                        {json2srcset(data, data.imgcls, "workblock")}
                    <section className="workblock-desc">
                        <h2>{data.name}</h2>
                        <p>{data.type}</p>
                    </section>
                </div>
            </Link>
        );
    }
    
    return (
        <div className="workblock-wrapper">
            {parseWB()}
        </div>
    );
}

export default WorkBlocks;