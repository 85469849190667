import React, { useRef, useState, useEffect } from 'react';

const HomeCanvas = () => {
    let ref = useRef();

    const getPixelRatio = context => {
            var backingStore =
            context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio ||
            1;
            return (window.devicePixelRatio || 1) / backingStore;
        };
    const [rd, setRD] = useState([document.documentElement.clientWidth - 20, document.documentElement.clientHeight - 20]);
    


    useEffect(() => {
        function dot (ctx, x, y, dx, dy, rad = (Math.random() * 3)) {
            this.x = x;
            this.y = y;
            this.dx = dx;
            this.dy = dy;
            this.rad = rad;
            var rad_d = Math.random() * 120;
            var alpha = 0;
            var ay = Math.random() / 1000;
            var rndcol = Math.floor(Math.random() * 4);
    
            this.draw = function() {
                var col = [`rgba(113, 134, 211, ${alpha})`,`rgba(217, 181, 224,${alpha})`,`rgba(77, 78, 102,${alpha})`, `rgba(159, 200, 204,${alpha})`];
                //console.log('r',rndcol);
                ctx.beginPath();
                
                ctx.arc(this.x, this.y, this.rad, 0, 2 * Math.PI );
                ctx.fillStyle = col[rndcol];
                ctx.fill();
            }
    
            this.update = function() {
                if (this.x < 0 || this.x > rd[0]) {
                    this.dx = -this.dx;
                }
    
                if (this.y < 0 || this.y > rd[1]) {
                    this.dy = -this.dy;
                }
    
                if (alpha < 0 || alpha > 0.4) {
                    ay = -ay;
                }
    
                if (this.rad < 2 || this.rad > 128) {
                    rad_d = -rad_d;
                }
                
                this.x += this.dx;
                this.y += this.dy;
                alpha += ay;
                rad += rad_d;
    
                this.draw();
            }
        }

        const handleResize = () => {
            setRD([document.documentElement.clientWidth, document.documentElement.clientHeight]);
            console.log("inW/inH",`${window.innerWidth} ${window.innerHeight}`);
            console.log("dcW/dcH",`${document.documentElement.clientWidth} ${document.documentElement.clientHeight}`);
        }        

        window.addEventListener('resize', handleResize);

        let canvas = ref.current;
        let ctx = canvas.getContext('2d');

        let ratio = getPixelRatio(ctx);
        //let width = getComputedStyle(canvas).getPropertyValue('width').slice(0, -2);
        //let height = getComputedStyle(canvas).getPropertyValue('height').slice(0, -2);
        let width = rd[0];
        let height = rd[1];
        
        canvas.width = width;
        canvas.height = height;
        console.log(ratio);
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        let requestId; 
        var maxStars = 500;
        var stArr = []
        for (let i = 0; i < maxStars; i++) {
            var x = Math.random() * rd[0];
            var y = Math.random() * rd[1];
            var dx = (Math.random() - 0.2) / 8.3;
            var dy = (Math.random() - 0.2) / 8.3;
            stArr.push(new dot(ctx, x, y, dx, dy));
        }
        const render = () => {

            requestId = requestAnimationFrame(render);
            ctx.clearRect(0, 0, rd[0], rd[1]);
            ctx.restore();
            for (let i = 0; i < stArr.length; i++) {
                stArr[i].update();
            }            
        };
        render();
     
        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(requestId);            
        };        
    }, [rd]);

    return (
        <canvas id="canvas" ref={ref}></canvas>
    )

}
export default HomeCanvas;