import React from 'react';
import json from '../../assets/json/works';
import json2srcset from '../../utilities/json2srcset';


const Portfolio = () => {
    let data = json.works[1];

    return (
        <section className="single-work port">
            <h1>aaronsham.com</h1>
            <div className="single-portfolio single-p">
                <p>This personal portfolio website serves as an introduction to who I am and what I can do. It catalogues and displays selected projects, like those from my time at BCIT's Technical Web Developer program. This website was a challenge on many fronts since I chose to tackle elements that were least familiar with me, which was the brief touch on with React.</p>
                {json2srcset(data, "port-screen inner-img", "screen")}
                <p>From the concept stage it was decided the site would be made in React as to challenge and solidify the concepts I had learned from an earlier project. As there were many new things that hadn't been done in the previous React project, there were a surprisingly amount of challenges that came barreling towards me. The navigation and header were a set of challenges that required multiple iterations to get right, especially since React had its own methods for certain things which were completely contrary to what I was used to (for example, jQuery violates the React state and generally should be avoided).</p>
                {json2srcset(data, "port-process inner-img", "process")}
                <p>The design did change from the initial spec slightly. In the original wireframes shown, I had intended the works to be shown in a sort of slider-carousel object. However, I decided against it and pursued a more traditional tiled grid with individual single pages, because I discovered the navigation between the posts would be cumbersome as I added more work, especially for mobile users. While I do want to perhaps revisit that design in the future, it would be with a fresh inspiration and a better approach to the interactivity side, especially for mobile users. </p>
            </div>
        </section>
    );
}

export default Portfolio;