import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {

    return (
        <main>
            <div className="content notf">
                <section className="intro">
                    <h1>Page Not Found</h1>
                    <p>The requested page was not found.</p>
                    <Link to="/">Return to the home page.</Link>
                </section>
                
            </div>
        </main>
    );
}

export default PageNotFound;