import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import json from '../assets/json/drinks';

const Home = () => {
    let data = json.drinks;
    const index = Math.floor(Math.random() * (data.length));
    const initialDrink = data[index];
    const [drink, setDrink] = useState([initialDrink, index]);
    const [pastDrinks, setPastDrinks] = useState([index, index, index]);
    
    useEffect( () => {
        const handleDrink = (n) => {
            //I can't, actually. lol.
            let pastArr = pastDrinks;
            do {
                n = Math.floor(Math.random() * (data.length));
            }
            while (n === drink[1] || n === pastArr[0] || n === pastArr[1] || n === pastArr[2]);
            pastArr.shift(0);
            pastArr.push(n);
            setPastDrinks(pastArr);
            setDrink([data[n], n]);
        }
        
        const $span = document.querySelector('.drinkSpan');
        const interval = window.setInterval(function() {
            let newRnd = Math.floor(Math.random() * (data.length));
            let start;
            const step = (timestamp) => {
                if (start === undefined) {
                    start = timestamp;
                }
                const elapsed = timestamp - start;
                $span.style.opacity = 0;

                if (elapsed < 300) {
                    window.requestAnimationFrame(step);
                } else {                    
                    handleDrink(newRnd);
                    $span.style.opacity = 1;
                }                 
            }
            window.requestAnimationFrame(step);
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [drink, data, pastDrinks]);

    return (
        <main>
            <div className="content home">
                <div className="intro">
                    <h1>Hi, I'm Aaron Sham.</h1>
                    <p>I'm a front-end web developer who likes to bring ideas to life through code.</p>
                    <p>I know a thing or two about design as well.</p> 
                    <p>If that sounds like your cup of <span className="drinkSpan">{drink[0]}</span>, let's talk.</p>
                    <div className="cta-btn">
                        <Link className="cta" to="/works">See what I've done &#10145;</Link>
                    </div>
                </div>
                <div className="posi-image">
                    <figure>
                        <img src="" alt="" />
                    </figure>
                </div>
            </div>
        </main>
    );
}

export default Home;