import React from 'react';
import { Link } from 'react-router-dom';

const WorkNotFound = () => {

    return (
        <section className="intro">
            <h1>Page Not Found</h1>
            <p>Uh oh, time for a Jedi mind trick...</p>
            <p>These are not the projects you're looking for.</p>
            <br />
            <Link to="/">Return to the Works page.</Link>
        </section>
    );
}

export default WorkNotFound;