import React from 'react';
import VProjectBtn from '../VProjectBtn';
import json from '../../assets/json/works';
import json2srcset from '../../utilities/json2srcset';


const Capstone = () => {
    let data = json.works[0];

    return (
        <section className="single-work cpst">
            {json2srcset(data, "full-width capst-splash", "splash")}
            <h1>Henri Bowen - Artist Portfolio</h1>
            <div className="single-portfolio single-p">
                <p>The Capstone project of BCIT's Technical Web Design program, is a WordPress site that acts as the online platform for a fictional artist named Henri Bowen.</p>
                    {json2srcset(data, "capst-process inner-img", "process")}
                    <p>I tackled this project in a team, and I was responsible for the gallery and event pages, their data and fields in the backend of WordPress, as well as their functionality and appearance. With PHP, I created templates that would retrieve the uploaded artwork and display them on their Gallery page. On the event pages, I learned how to filter posts by <span className="codenm">meta_query</span> calls. It's quite flexible and can be used to pull information about how a post can and should be classified.</p>
                <p>The layout on the gallery was created using a plug-in called Isotope. At the time I had little experience working with third-party plug-ins but I eventually soon got it working.</p>
                <p>This project isn't actually finished yet, but will be soon!</p>
            </div>
            <div className="vproj-wrapper">
                <VProjectBtn url={"https://henribowen.bcitwebdeveloper.ca/"}/>
            </div>
        </section>        
    );
}

export default Capstone;