import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Nav from './Nav';
import json from '../assets/json/works';
import logo from '../assets/images/mark-as.svg';

const Header = () => {
    const loc = useLocation().pathname;
    const dirs = ['/works/', '/'];
    const [scroll, setScroll] = useState('');

    const handleScroll = () => {
        const solidify = window.scrollY > 24;
        if (solidify) {
            setScroll('bgn');
        } else {
            setScroll('');
        }
    }

    useEffect( () => {        
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }        
    }, []);

    const splashClass = () => {
        let data = json.works;
        let comp = false;
        for (let i = 0; i < dirs.length; i++) {
            if (dirs[i] === '/works/') {
                for (let j = 0; j < data.length; j++) {
                    if (loc === dirs[i]+data[j].slug) { comp = true; break; }
                }
            // manually flag other pages here, add to dirs
            } else if (dirs[i] === '/') {
                comp = true; break;
            }
        };
        if (scroll !== '') {
            if (comp) { return `hasSplash ${scroll}`; } else { return; }            
        } else {
            if (comp) { return 'hasSplash'; } else { return; }
        }
        
    }

    return (
        <header className={splashClass()} onScroll={handleScroll}>
            <div className="logo">
                <Link to="/"><img src={logo} width="64px" height="auto" alt="logo" /></Link>
            </div>
            <Nav />
        </header>
    );
}

export default Header;