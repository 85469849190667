import React from 'react';
import json from '../assets/json/ssb';
import { sbi } from '../globals/variables';

const AboutSkillset = () => {

    const parseSSB = () => {
        return json.ssb.map((block, i) => {
            return skillsetBlock(block, i);
        })
    }

    const skillsetBlock = (data, key) => {
        return(
        <div className="ssb" key={key}>
            <img className="ssb-icon" src={sbi[key]} alt={data.name+" icon"}/>
            <div>
                <p className="ssb-title">{data.name}</p>
                <p className="ssb-desc">{data.desc}</p>
            </div>
        </div>
        );
    }

    return (
        // Credit to Twitter for the Twemoji
        <section className="skillset">
            <p className="skillset-title">Skill-Set</p>
            <div className="ssb-wrapper">
                {parseSSB()}
            </div>
        </section>
    );
}

export default AboutSkillset;