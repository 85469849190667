import React from 'react';

const VProjectBtn = ({url}) => {
    let link = url;
    console.log(url)

    return (
        <div className="vproject-btn">
            <a className="vproject" href={link} target="blank">
                See project
            </a>
        </div>
    );
}
export default VProjectBtn;