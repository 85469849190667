import React from 'react';

const Footer = () => {

    return(
        <footer>
            <div className="footer-icons">
                <ul>
                    <li><a href="https://www.linkedin.com/in/aaron-sham-314b05117/">LinkedIn</a></li>
                    <li><a href="https://twitter.com/aaronksham/">Twitter</a></li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;