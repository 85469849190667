import React from 'react';
import AboutSkillset from './AboutSkillset';
import verynice from '../assets/images/verynice-4x.gif';

const About = () => {
    return (
        <main>
            <div className="content about">
                <section className="intro">
                    <h1>Who am I, anyways?</h1>
                    <div className="intro-body">
                        <figure className="portrait">
                            <img src={verynice} width="336px" height="auto" alt="very nice dog pixel art" />
                        </figure>
                        
                        <div className="intro-text">
                            <h2>Name's Aaron Sham.</h2>
                            <p>Born and raised in Burnaby, BC, I discovered my passions while pursuing creative endeavours. Previously a freelance graphic designer, I now strive to create sleek websites through my growing coding knowledge.</p>
                            <p>When I'm not coming up with new ideas, I can be found playing the piano or video games, or just making bad puns and pixel art.</p>
                            <p>I'm not actually a dog wearing sunglasses, but that'd be pretty cool.</p>
                        </div>
                    </div>
                </section>
                <AboutSkillset />
            </div>
        </main>
    );
}

export default About;