import React from 'react';
import WorkBlocks from './WorkBlocks';

const Works = () => {
    return (
        <main>
            <div className="content works">
                <section className="intro">
                    <h1>My Projects</h1>
                </section>
                <WorkBlocks />
            </div>
        </main>
    );
}

export default Works;