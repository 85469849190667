import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    // mobile menu nav state
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect( () => {
        window.addEventListener('resize', handleClose);
        return () => {
            window.removeEventListener('resize', handleClose);
        }        
    }, []);
       
    return(
        <div className="menu">
            <button className={open ? `hamburger hamburger--squeeze burger-open is-active` : `hamburger hamburger--squeeze`} type="button" onClick={handleClick}>
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <div className={open ? "nav-backdrop show" : "nav-backdrop" } onClick={handleClick}></div>
            <nav className={open ? "nav-wrapper show" : "nav-wrapper"}> 
                <ul className={open ? "navlinks show" : "navlinks"} onClick={handleClick}>
                    <li className="mob-blurb">Where to?</li>
                    <li className="mob-home"><NavLink to="/" exact>home</NavLink></li>
                    <li><NavLink to="/about" activeClassName="active" exact >about</NavLink></li>
                    <li><NavLink to="/works" activeClassName="active" exact>works</NavLink></li>
                    <li><NavLink to="/contact" activeClassName="active" exact>contact</NavLink></li>
                </ul>            
            </nav>
        </div>
    );
}

export default Nav;