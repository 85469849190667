import React from 'react';
import { srcSizes, wbi, sbi, splash, screen, process } from '../globals/variables';

const json2srcset = (d, cls = null, type = null) => {
    let rby = d.slug;

    const doType = (type) => {
        switch(type) {
            case "workblock": return wbi;
            case "ssb": return sbi;
            case "process": return process;
            case "screen": return screen;            
            case "splash": return splash;
            default: return null;
        }
    }
        
    return(
        <figure className={`${cls ? cls : ""}`}>
            <img className={`${cls ? cls : ""}`} src={doType(type)[rby][0]} 
            srcSet={`${doType(type)[rby][0]} ${srcSizes[0]}, ${doType(type)[rby][1]} ${srcSizes[1]}, ${doType(type)[rby][2]} ${srcSizes[2]}`} 
            sizes={`(min-width: ${srcSizes[0]}) ${srcSizes[0]}, (min-width: ${srcSizes[1]}) ${srcSizes[1]} (min-width: ${srcSizes[2]}) ${srcSizes[2]}`}
            alt={d.desc}/>
        </figure>
    )
}
export default json2srcset;