import React from 'react';
import { useParams } from 'react-router-dom';
import Capstone from './works/Capstone';
import Portfolio from './works/Portfolio';
import ReelTalk from './works/ReelTalk';
import PhantomBuster from './works/PhantomBuster';
import WorkNotFound from './works/WorkNotFound';
import SinglePostNav from './SinglePostNav';

const SingleWork = () => {
    const id = useParams().id;

    const chkNotF = (id) => {
        if (id !== "capstone" &&
        id !== "portfolio" &&
        id !== "reeltalk" &&
        id !== "phantombuster"
        ) {
            return true;
        } else {
            return false;
        }
    }

    const singleData = (id) => {

        switch(id) {
            case "capstone":
                return <Capstone />;
            case "portfolio":
                return <Portfolio />;
            case "reeltalk":
                return <ReelTalk />;
            case "phantombuster":
                return <PhantomBuster />;
            default:
                return <WorkNotFound />;
        }
    }

    return (
        <main>
            <div className={`content ${chkNotF(id) ? `notf` : ``}`}>
                {singleData(id)}
            </div>
            <SinglePostNav id={id} />
        </main>
    );
}

export default SingleWork;