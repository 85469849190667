export const APP_FOLDER_NAME = './';

//About Skillsets
export const SSB_JSON = '../assets/json/ssb.json';

export const srcSizes = ["320w", "768w", "1400w"];

//ssb images 
export const sbi = [
    require("../assets/images/ssb/1f4f1.png"),
    require("../assets/images/ssb/1f35e.png"),
    require("../assets/images/ssb/1f39a.png"),
    require("../assets/images/ssb/1f4cf.png"),
    require("../assets/images/ssb/1f5bc.png"),
    require("../assets/images/ssb/1f34a.png"),
]

//workblock images
export const wbi = {
    capstone:[
        require("../assets/images/works/capstone/bowen-1x.jpg"),
        require("../assets/images/works/capstone/bowen-2x.jpg"),
        require("../assets/images/works/capstone/bowen-4x.jpg"),
    ],
    portfolio:[
        require("../assets/images/works/portfolio/mark-as-1x.gif"),
        require("../assets/images/works/portfolio/mark-as-2x.gif"),
        require("../assets/images/works/portfolio/mark-as-4x.gif"),
    ],
    reeltalk:[
        require("../assets/images/works/reeltalk/reeltalk-1x.gif"),
        require("../assets/images/works/reeltalk/reeltalk-2x.gif"),
        require("../assets/images/works/reeltalk/reeltalk-4x.gif"),
    ],
    phantombuster:[
        require("../assets/images/works/phantombuster/pbuster-key-1x.gif"),
        require("../assets/images/works/phantombuster/pbuster-key-2x.gif"),
        require("../assets/images/works/phantombuster/pbuster-key-4x.gif"),
    ],
}

export const screen = {
    capstone:[
        require("../assets/images/works/capstone/single-bowen-process-025x.jpg"),
        require("../assets/images/works/capstone/single-bowen-process-05x.jpg"),
        require("../assets/images/works/capstone/single-bowen-process-1x.jpg"),
    ],
    portfolio:[
        require("../assets/images/works/portfolio/single-port-process1-025x.png"),
        require("../assets/images/works/portfolio/single-port-process1-05x.png"),
        require("../assets/images/works/portfolio/single-port-process1-1x.png"),
    ],
    reeltalk:[
        require("../assets/images/works/reeltalk/single-rt-screen-025x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-screen-05x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-screen-1x.jpg"),
    ],
    phantombuster:[
        require("../assets/images/works/phantombuster/single-pb-screen-025x.jpg"),
        require("../assets/images/works/phantombuster/single-pb-screen-05x.jpg"),
        require("../assets/images/works/phantombuster/single-pb-screen-1x.jpg"),
    ],
};

export const process = {
    capstone:[
        require("../assets/images/works/capstone/single-bowen-process-025x.jpg"),
        require("../assets/images/works/capstone/single-bowen-process-05x.jpg"),
        require("../assets/images/works/capstone/single-bowen-process-1x.jpg"),
    ],
    portfolio:[
        require("../assets/images/works/portfolio/single-port-process2-025x.png"),
        require("../assets/images/works/portfolio/single-port-process2-05x.png"),
        require("../assets/images/works/portfolio/single-port-process2-1x.png"),
    ],
    reeltalk:[
        require("../assets/images/works/reeltalk/single-rt-process-025x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-process-05x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-process-1x.jpg"),
    ],
    phantombuster:[
        require("../assets/images/works/phantombuster/single-pb-process-025x.jpg"),
        require("../assets/images/works/phantombuster/single-pb-process-05x.jpg"),
        require("../assets/images/works/phantombuster/single-pb-process-1x.jpg"),
    ],
};


export const splash = {
    capstone:[
        require("../assets/images/works/capstone/single-bowen-splash-025x.jpg"),
        require("../assets/images/works/capstone/single-bowen-splash-05x.jpg"),
        require("../assets/images/works/capstone/single-bowen-splash-1x.jpg"),
    ],
    portfolio:[
        require("../assets/images/works/phantombuster/single-pb-titlescreen-05x.png"),
        require("../assets/images/works/phantombuster/single-pb-titlescreen-1x.png"),
        require("../assets/images/works/phantombuster/single-pb-titlescreen-2x.png"),
    ],
    reeltalk:[
        require("../assets/images/works/reeltalk/single-rt-splash-025x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-splash-05x.jpg"),
        require("../assets/images/works/reeltalk/single-rt-splash-1x.jpg"),
    ],
    phantombuster:[
        require("../assets/images/works/phantombuster/single-pb-titlescreen-05x.png"),
        require("../assets/images/works/phantombuster/single-pb-titlescreen-1x.png"),
        require("../assets/images/works/phantombuster/single-pb-titlescreen-2x.png"),
    ],
};
