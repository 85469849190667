import React from 'react';

const Contact = () => {
    return (
        <main>
            <div className="content ctct">
                <section className="intro">
                    <h1>Come and say hello</h1>                    
                </section>
                <section className="info">
                    <section className="email">
                        <p className="hlh">Email</p>
                        <p>Email's probably the best way to get in touch with me.</p>
                        <p><a className="hlt" href="mailto:aaronksham@gmail.com">aaronksham@gmail.com</a></p>
                        
                    </section>
                    <section className="social">
                        <p className="hlh">Social</p>
                        <p>You can also find me on social media:</p>
                        <p>Twitter: <a className="hlt" href="https://twitter.com/aaronksham/">@aaronksham</a></p>
                        <p>LinkedIn: <a className="hlt" href="https://www.linkedin.com/in/aaron-sham-314b05117/">Aaron Sham</a></p>
                    </section>
                </section>
            </div>
        </main>
    );
}

export default Contact;